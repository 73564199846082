import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from  '../i18n';
import Logo from '../img/logo.svg';
import FlagUSA from '../img/icons/flag-usa.svg';
import FlagNLD from '../img/icons/flag-netherlands.svg';

export default function Menu(props) {
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const lng = i18n.language.split('-')[0];
  const baseUrl = '/' + lng + '/';

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setHasScrolled(window.scrollY > 1);
    });
  })

  return (
    <Navbar variant="dark" expand="lg" className={'site-menu ' + (hasScrolled ? 'scrolled' : '')} expanded={expanded}>
      <Navbar.Brand>
        <Link to={baseUrl} className="site-logo" title="pimaxplus.com">
          <div className="logo-img">
            <img src={Logo} alt="pimaxplus.com" />
          </div>
          <div className="name-and-tagline">
            <div className="name">
              pimaxplus<span className="red">.com</span>
            </div>
            <div className="tagline">
              IT consultancy
            </div>
          </div>
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <NavLink to={baseUrl} className="nav-link" onClick={() => setTimeout(() => {setExpanded(false)}, 150)}>
            {t('Home')}
          </NavLink>
          {/* <NavLink to={baseUrl + (lng === 'nl' ? 'over' : 'about')} className="nav-link" onClick={() => setTimeout(() => {setExpanded(false)}, 150)}>
            {t('About')}
          </NavLink> */}
          <NavLink to={baseUrl + 'blog'} className="nav-link" onClick={() => setTimeout(() => {setExpanded(false)}, 150)}>
            {t('Blog')}
          </NavLink>
          <NavLink to={baseUrl + 'contact'} className="nav-link" onClick={() => setTimeout(() => {setExpanded(false)}, 150)}>
            {t('Contact')}
          </NavLink>
          <span className="nav-link flags">
            <img src={FlagNLD} alt="Nederlands" height="16" onClick={() => props.changeLanguage('nl')} />
            <img src={FlagUSA} alt="English" height="16" onClick={() => props.changeLanguage('en')} />
          </span>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  ); 
}